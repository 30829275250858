<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-radio-group v-model="status" label="Status de l'opération">
            <v-radio label="Réussi" value="success"></v-radio>
            <v-radio label="Échoué" value="error"></v-radio>
          </v-radio-group>

          <v-dialog
              ref="dialog"
              v-model="modal"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined
                            dense
                            @click:clear="dates = []"
                            v-model="dateRangeText"
                            readonly
                            append-icon="mdi-calendar-outline"
                            clearable
                            label="Dates..."
                            v-bind="attrs"
                            v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker v-model="dates" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Fermer
              </v-btn>
              <v-btn text
                     color="primary"
                     :disabled="dates.length < 2"
                     @click="modal = false">
                ok
              </v-btn>
            </v-date-picker>
          </v-dialog>

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-btn color="primary"
                 @click="handleFilter"
                 :disabled="dates.length < 2  && !status"
                 depressed block
                 type="submit">
            <v-icon left>mdi-magnify</v-icon>
            Rechercher
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      isDBLoading: false,
      modal: false,
      dialog: false,
      status: null,
      dates: [],
    }
  },
  methods: {
    open() {
      this.dates = []
      this.status = null
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    handleFilter() {
      let data = {
        dates: this.dates,
        status: this.status,
      }
      this.$emit('filter', data)
      this.close()
    },
  },
  computed: {
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ')
      },
      set(dates) {
        return dates
      }
    }
  },
}
</script>

