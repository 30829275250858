<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>

              <v-col v-if="can('supplier')" cols="12" lg="3"
                     style="border-right: 1px solid #eaeaea">
                <Menu/>
              </v-col>

              <v-col v-if="can('store')"
                     class="d-none d-lg-block"
                     cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <StoreCard/>
              </v-col>

              <v-col cols="12" lg="9">
                <v-card flat>

                  <v-card-title>
                    Historique des scans
                    <v-spacer/>
                    <v-btn :to="'/partner-vouchers/store/'+$route.params.id"
                           depressed
                           exact
                           fab
                           small>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-divider/>

                  <v-card-title>
                    <div>
                      <v-btn class="rounded-lg text-none" color="grey" text>
                        <v-icon color="gifty" left size="22">mdi-data-matrix-scan</v-icon>
                        Total opérations ({{ total }})
                      </v-btn>

                      <v-btn class="rounded-lg text-none" color="grey" text>
                        <v-icon color="success" left size="22">mdi-check-circle</v-icon>
                        Opérations réussies ({{ statistics.successCount }})
                      </v-btn>

                      <v-btn class="rounded-lg text-none" color="grey" text>
                        <v-icon color="error" left size="22">mdi-close-circle</v-icon>
                        Opérations échouées ({{ statistics.errorCount }})
                      </v-btn>
                    </div>
                  </v-card-title>

                  <v-divider/>

                  <v-overlay :value="overlay"
                             absolute
                             color="white"
                             opacity="0.5">
                    <v-progress-circular
                            color="primary"
                            indeterminate
                            size="50"
                    ></v-progress-circular>
                  </v-overlay>

                  <v-card-title>
                    <div>
                      <v-text-field
                              v-model="keyword"
                              append-icon="mdi-magnify"
                              clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded
                              single-line
                      ></v-text-field>
                    </div>

                    <v-spacer/>

                    <v-btn color="grey"
                           text
                           @click="$refs.filterDialog.open()">
                      <v-icon left>mdi-filter-outline</v-icon>
                      Filtre
                    </v-btn>

                    <v-btn color="grey"
                           icon
                           @click="handleFilter">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>

                  </v-card-title>

                  <v-divider/>

                  <v-card-text v-if="isLoading" class="pa-0">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>

                    <v-card-text class="pa-0">

                      <v-simple-table v-if="scans.length">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>Utilisateur</th>
                            <th>Code</th>
                            <th>Scanner le</th>
                            <th>Message</th>
                            <th class="text-right">Statut</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in scans" :key="i">

                            <td class="font-weight-medium text-no-wrap">
                              <v-avatar size="35">
                                <v-img :src="require('@/assets/avatar.png')"/>
                              </v-avatar>
                              {{ item.user.name }}
                            </td>

                            <td class="text-no-wrap">
                          <span v-if="item.voucher">
                               {{ item.voucher.code }}
                          </span>
                              <span v-else class="red--text font-weight-medium">Code invalide</span>
                            </td>

                            <td class="text-no-wrap">
                              <v-icon :style="{marginTop:'-3px'}"
                                      color="gifty"
                                      small>mdi-clock-outline
                              </v-icon>
                              {{ item.scan_at }}
                            </td>

                            <td>
                              {{ item.message }}
                            </td>

                            <td class="text-right">
                              <v-icon v-if="item.status === 'success'" color="success">mdi-check-circle</v-icon>
                              <v-icon v-if="item.status === 'error'" color="error">mdi-close-circle</v-icon>
                            </td>

                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getScanHistories"/>
                      </div>

                    </v-card-text>

                    <v-divider v-if="total > 10"/>

                    <v-card-actions v-if="total > 10">

                      <div :style="{width : '120px'}">
                        <v-select
                                v-model="perPage"
                                :items="[10,20,30,50,100]"
                                dense
                                hide-details
                                label="Ligne par page"
                                outlined
                        ></v-select>
                      </div>

                      <v-spacer/>

                      <v-pagination v-if="total > 10"
                                    v-model="pagination.current"
                                    :length="pagination.total"
                                    circle
                                    total-visible="8"
                                    @input="getScanHistories"></v-pagination>

                    </v-card-actions>

                  </div>

                </v-card>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>

      </div>

      <FilterDialog ref="filterDialog" @filter="handleFilter($event)"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import FilterDialog from "@/views/partner_vouchers/scan-history/componenets/FilterDialog"
import Menu from "@/views/partner_vouchers/components/Menu";
import StoreCard from "@/views/partner_vouchers/components/StoreCard";

export default {
   components: {
      FilterDialog,
      NoResults,
      Menu,
      StoreCard,
   },
   data() {
      return {
         id: null,
         isLoading: false,
         overlay: false,

         keyword: null,
         status: null,

         scans: [],
         total: 0,
         perPage: 10,
         pagination: {
            current: 1,
            total: 0
         },

         statistics: {
            successCount: 0,
            errorCount: 0,
         }

      };
   },
   methods: {
      getScanHistories() {
         this.isLoading = true
         HTTP.get('/v1/partner-vouchers/scan-histories?page=' + this.pagination.current, {
            params: {
               perPage: this.perPage,
               keyword: this.keyword,
               dates: this.dates,
               status: this.status,
               store_id: this.$route.params.id,
            }
         }).then(res => {
            this.isLoading = false
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;

            this.scans = res.data.data.data
            this.statistics = res.data.statistics

         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
      handleFilter(e) {
         this.dates = e.dates
         this.status = e.status
         this.getScanHistories()
      },
   },
   created() {
      this.getScanHistories()
   },
   watch: {
      perPage() {
         this.pagination.current = 1
         this.getScanHistories()
      },
      keyword() {
         this.getScanHistories()
      },
   },
};
</script>

<style scoped></style>
